import React from "react";
import { Card, Col, Container, Row, Table, Navbar, Nav, NavDropdown, Button} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const Lima = () => {
    const navigate = useNavigate();
    const customFontSize = { fontSize: '1.4rem' };

    return (
        <div>
            <Navbar className="myBG" sticky="top" expand="lg" style={customFontSize}>
        <Container className="text-dark">
          <Navbar.Brand className="judulnavbar" style={customFontSize}>SD NEGERI MEDAYU 01</Navbar.Brand>
          <Nav className="ml-auto tabnavbar" style={customFontSize}>
            <Nav.Link onClick={() => navigate('/')} style={customFontSize}>Home</Nav.Link>
            <NavDropdown title="Sekolah" id="collapsible-nav-dropdown" style={customFontSize}>
              <NavDropdown.Item onClick={() => navigate('/profil')} style={customFontSize}>Profil</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/latar')} style={customFontSize}>Latar</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/legalitas')} style={customFontSize}>Legalitas</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Data" id="collapsible-nav-dropdown" style={customFontSize}>
              <NavDropdown.Item onClick={() => navigate('/dataguru')} style={customFontSize}>Data Guru</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
      <div className="guruguru">
        <Container>
         <Row className="my-4">
          <Col>
          <Card className="dtguru">
            <Card.Body>
            <Card.Title>Data Guru SD Negeri 01 Medayu</Card.Title>
            <Card.Text>Berikut adalah data data guru yang ada di SD Negeri 01 Suruh</Card.Text>
            </Card.Body>
          </Card>
          </Col>
         </Row>

        <Row>
            <Col>
              <Table striped bordered hover variant="light" >
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Jk</th>
                        <th>Nama</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Kepala Sekolah</td>
                        <td>P</td>
                        <td>Daryati S.Pd</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>Guru Kelas</td>
                        <td>P</td>
                        <td>Annisa Sarafina S.Pd</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>Guru Kelas</td>
                        <td>P</td>
                        <td>Bahriyah S.Pd</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>Guru Kelas</td>
                        <td>L</td>
                        <td>Punky Nur Setyono S.Pd</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>Guru Kelas</td>
                        <td>P</td>
                        <td>Rifa Yuliani S.Pd</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>Guru Kelas</td>
                        <td>L</td>
                        <td>Rini Widyastuti S.Pd</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>Guru Kelas</td>
                        <td>L</td>
                        <td>Shohifudin S.Pd</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>Guru Kelas</td>
                        <td>P</td>
                        <td>Sri Wahyuningsih S.Pd</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>Guru Kelas</td>
                         <td>P</td>
                        <td>Wahyu Titis Kholifah S.Pd</td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td>Guru Kelas</td>
                        <td>P</td>
                        <td>Zulfa Wardani S.Pd</td>
                    </tr>
                </tbody>
              </Table>
            </Col>
        </Row>
        </Container>
        </div>
        <div className='footer-background'>
      <footer className="footer">
        <Container>
          <Row>
            <Col md={4} className="footer-section">
              <h5>Contact Us</h5>
              <p>
                Alamat: JL. Medayu, RT. 14, RW. 4, Suruh, Kalisal Satu, Reksosari, Kec. Suruh, Kabupaten Semarang, Jawa Tengah 50776<br />
                Phone: 08170581014<br />
                Email: medayusd@gmail.com
              </p>
            </Col>
            <Col md={4} className="footer-section">
              <h5>Link</h5>
              <p onClick={() => navigate('/latar')} style={{ cursor: 'pointer' }}>Latar Sekolah</p>
              <p onClick={() => navigate('/profil')} style={{ cursor: 'pointer' }}>Profil</p>
              <p onClick={() => navigate('/legalitas')} style={{ cursor: 'pointer' }}>Legalitas</p>
            </Col>
            <Col md={4} className="footer-section">
              <h5>Follow Us</h5>
              <div className="d-flex flex-column align-items-start">
                <Button variant="danger" href="https://www.instagram.com/sdnegerimedayu01/" className="mb-2">
                  <i className="bi bi-instagram" href="https://www.instagram.com/sdnegerimedayu01/"></i> Instagram
                </Button>
                <Button variant="success" href="https://chat.whatsapp.com/GqGvSQwzntDE1Vtq8rbDXX" className="mb-2">
                  <i className="bi bi-whatsapp"></i> WhatsApp
                </Button>
                <Button variant="primary" href='https://www.facebook.com/share/NMNH8okoWKiTMphF/?mibextid=LQQJ4d' className="mb-2">
                  <i className="bi bi-facebook"></i> Facebook
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3">
              <p className="footer-text">&copy; {new Date().getFullYear()} SD Negeri Medayu 01 x Agus Dwi Saputro. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
        </div>
    )
}

export default Lima