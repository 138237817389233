import React from 'react';
import { NavDropdown,Container, Row, Col, Navbar, Nav, Button  } from 'react-bootstrap'; // Import necessary components from react-bootstrap
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const Dua = () => {
  const navigate = useNavigate();
  const customFontSize = { fontSize: '1.4rem' };

  return (
    <div className='home'>
      <Navbar className="myBG" sticky="top" expand="lg" style={customFontSize}>
        <Container className="text-dark">
          <Navbar.Brand className="judulnavbar" style={customFontSize}>SD NEGERI MEDAYU 01</Navbar.Brand>
          <Nav className="ml-auto tabnavbar" style={customFontSize}>
            <Nav.Link onClick={() => navigate('/')} style={customFontSize}>Home</Nav.Link>
            <NavDropdown title="Sekolah" id="collapsible-nav-dropdown" style={customFontSize}>
              <NavDropdown.Item onClick={() => navigate('/profil')} style={customFontSize}>Profil</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/latar')} style={customFontSize}>Latar</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/legalitas')} style={customFontSize}>Legalitas</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Data" id="collapsible-nav-dropdown" style={customFontSize}>
              <NavDropdown.Item onClick={() => navigate('/dataguru')} style={customFontSize}>Data Guru</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
      <footer className='latar'>
        <Container className='my-5'>
          <Row>
            <Col md={4} className="text-start mt-5">
              <h5 className='latar-text'>SD Negeri Medayu 01</h5>
              <p className='latar-text1'>
              SD Negeri Medayu 01 adalah sebuah institusi pendidikan  SD negeri yang beralamat di Medayu,Kab. Semarang.
              SD negeri ini didirikan pertama kali pada tahun 1987. Pada saat ini SD Negeri Medayu 01 menggunakan kurikulum belajar SD 2013. SD Negeri Medayu 01 dibawah kepemimpinan seorang kepala sekolah yang bernama Siti Hidayah dan operator sekolah Zulfa Wardani.              
              </p>
            </Col>
            <Col md={4} className="text-start mt-5">
              <h5 className='latar-text'>Akreditasi SD Negeri Medayu 01</h5>
              <p className='latar-text1'>
              SD Negeri Medayu 01 mendapat status akreditasi grade A dengan nilai 91 (akreditasi tahun 2021) dari BAN-S/M (Badan Akreditasi Nasional) Sekolah/Madrasah. 
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
      
      <div className='footer-background'>
      <footer className="footer">
        <Container>
          <Row>
            <Col md={4} className="footer-section">
              <h5>Contact Us</h5>
              <p>
                Alamat: JL. Medayu, RT. 14, RW. 4, Suruh, Kalisal Satu, Reksosari, Kec. Suruh, Kabupaten Semarang, Jawa Tengah 50776<br />
                Phone: 08170581014<br />
                Email: medayusd@gmail.com
              </p>
            </Col>
            <Col md={4} className="footer-section">
              <h5>Link</h5>
              <p onClick={() => navigate('/latar')} style={{ cursor: 'pointer' }}>Latar Sekolah</p>
              <p onClick={() => navigate('/profil')} style={{ cursor: 'pointer' }}>Profil</p>
              <p onClick={() => navigate('/legalitas')} style={{ cursor: 'pointer' }}>Legalitas</p>
            </Col>
            <Col md={4} className="footer-section">
              <h5>Follow Us</h5>
              <div className="d-flex flex-column align-items-start">
                <Button variant="danger" href="https://www.instagram.com/sdnegerimedayu01/" className="mb-2">
                  <i className="bi bi-instagram" href="https://www.instagram.com/sdnegerimedayu01/"></i> Instagram
                </Button>
                <Button variant="success" href="https://chat.whatsapp.com/GqGvSQwzntDE1Vtq8rbDXX" className="mb-2">
                  <i className="bi bi-whatsapp"></i> WhatsApp
                </Button>
                <Button variant="primary" href='https://www.facebook.com/share/NMNH8okoWKiTMphF/?mibextid=LQQJ4d' className="mb-2">
                  <i className="bi bi-facebook"></i> Facebook
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3">
              <p className="footer-text">&copy; {new Date().getFullYear()} SD Negeri Medayu 01 x Agus Dwi Saputro. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
    </div>
  );
}

export default Dua;
