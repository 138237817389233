import React from 'react';
import './App.css'; 
import Satu from './Component/Satu';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Latar from './Pages/Latar';import Dua from './Component/Dua';
import Home from './Pages/Home';
import Tiga from './Component/Tiga';
import Empat from './Component/Empat';
import Lima from './Component/Lima';



const App = () => {
  return (
    <Router> 
      <Routes>
        <Route path='/' element={<Satu />} /> 
        <Route path='/latar' element={<Dua />} /> 
        <Route path='/profil' element={<Tiga />} />
        <Route path='/legalitas' element={<Empat />} />
        <Route path='/dataguru' element={<Lima />} />
      </Routes>
    </Router>
  );
}

export default App;
