import React, { useState } from 'react';
import { NavDropdown, Navbar, Container, Nav, Row, Col, Carousel, Card, CardGroup, Button  } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import bgImage4 from '../Image/bgImage4.jpg'
import bgImage5 from '../Image/bgImage5.jpg'
import bgupacara from '../Image/bgupacara.jpeg'
import bgupacara2 from '../Image/bgupacara2.jpeg'
import background3 from '../Image/background3.jpg'
import Rebana from '../Image/Rebana.jpg'
import Rebana1 from '../Image/Rebana1.jpg'
import Rebana2 from '../Image/Rebana2.jpg'
import bgtari from '../Image/bgtari.jpg'
import bgtari1 from '../Image/bgtari1.jpg'
import bgtari2 from '../Image/bgtari2.jpg'
import wasanawarsa from '../Image/wasanawarsa.jpg'
import wasanawarsa1 from '../Image/wasanawarsa1.jpg'
import bgguru from '../Image/bgguru.jpg'
import bgguru2 from '../Image/bgguru2.jpg'
import bgguru3 from '../Image/bgguru3.jpg'




const Satu = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const customFontSize = { fontSize: '1.4rem' };

  return (
    
    <div className='home'>
      <Navbar className="myBG" sticky="top" expand="lg" style={customFontSize}>
        <Container className="text-dark">
          <Navbar.Brand className="judulnavbar" style={customFontSize}>SD NEGERI MEDAYU 01</Navbar.Brand>
          <Nav className="ml-auto tabnavbar" style={customFontSize}>
            <Nav.Link onClick={() => navigate('/')} style={customFontSize}>Home</Nav.Link>
            <NavDropdown title="Sekolah" id="collapsible-nav-dropdown" style={customFontSize}>
              <NavDropdown.Item onClick={() => navigate('/profil')} style={customFontSize}>Profil</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/latar')} style={customFontSize}>Latar</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/legalitas')} style={customFontSize}>Legalitas</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Data" id="collapsible-nav-dropdown" style={customFontSize}>
              <NavDropdown.Item onClick={() => navigate('/dataguru')} style={customFontSize}>Data Guru</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
      
      
      <div>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block carousel-image"
            src={bgupacara}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>SD Negeri Medayu 01</h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block carousel-image"
            src={bgupacara2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>SD Negeri Medayu 01</h3>
            <p>Mencerdaskan siswa dan Siswi Yang berprestasi</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
      
      <div className="informasi">
      <Container className='text-center'>
        <h1 className='kata1'>Informasi & Pendaftaran Siswa/Siswi Baru</h1>
        <h3 className='kata3'>SD Negeri Medayu 01 Berhasil mendidik para siswa dan siswi</h3>
        <Button
          variant="success"
          href="https://chat.whatsapp.com/GqGvSQwzntDE1Vtq8rbDXX"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-3"
        >
          <i className="bi bi-whatsapp"></i> WhatsApp
        </Button>
      </Container>
    </div>
      
      <div className='ourprogram'>
        <Container className='kata10 text-center'>
          <h1 className='kata3'>Our Program</h1>
          <h3 className='kata4'>SD NEGERI MEDAYU 01</h3>
        </Container>
      
        <CardGroup className='card-group-custom justify-content-center'>
          <Card className='custom-card'>
            <Card.Body>
              <Card.Title className='text-center'>Fasilitas</Card.Title>
              <Card.Text className='text-center'>
                Terdapat beberapa fasilitas yang ada di SD Negeri Medayu 01 seperti ruang komputer dan perpustakaan.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='custom-card'>
            <Card.Body className='text-center'>
              <Card.Title>Program Unggulan</Card.Title>
              <Card.Text>
                Program unggulan di SD Negeri Medayu 01 mencakup pelatihan kedisiplinan, kreativitas, dan inovasi siswa.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className='custom-card'>
            <Card.Body className='text-center'>
              <Card.Title>Prestasi</Card.Title>
              <Card.Text>
                SD Negeri Medayu 01 memiliki banyak prestasi di bidang akademik dan non-akademik.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      
      <div className='mengapa justify-content-center align-items-center'>
        <Container className='kata text-center'>
          <h1 className='kata5'>Mengapa Memilih SD Negeri Medayu 01?</h1>
        </Container>
        <footer className='memilih'>
          <Container>
            <Row>
              <Col md={4} className="text-center">
                <h5>Kualitas Pendidikan</h5>
                <p>SD Negeri Medayu 01 menawarkan pendidikan berkualitas tinggi dengan kurikulum yang inovatif.</p>
              </Col>
              <Col md={4} className="text-center">
                <h5>Lingkungan Belajar</h5>
                <p>Lingkungan belajar yang kondusif dan ramah untuk semua siswa.</p>
              </Col>
              <Col md={4} className="text-center">
                <h5>Ekstrakurikuler</h5>
                <p>Beragam kegiatan ekstrakurikuler yang mendukung pengembangan minat dan bakat siswa.</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
      
      <div className='pendaftaran'>
        <Container className='kata10 text-center'>
          <h1 className='kata5'>PENDAFTARAN PESERTA DIDIK BARU</h1>
          <h3 className='kata6'>Kami Mengundang putra&putri terbaik Negeri untuk bergabung bersama SD Negeri Medayu 01</h3>
          <Button
          variant="success"
          href="https://chat.whatsapp.com/GqGvSQwzntDE1Vtq8rbDXX"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-3"
        >
            <i className="bi bi-whatsapp"></i> WhatsApp
           </Button>
        </Container>
      </div>
      
      <div className='foto'>
        <Container className='kata10 text-center'>
          <Row>
            <Col>
            <h1 className='kata5'>GALERI SD NEGERI MEDAYU 01</h1>
            </Col>
          </Row>
          <Row>
            <Col>
            <Carousel className='acara'>
      <Carousel.Item>
      <Row>
            <Col>
            <h1 className='kata3'>Wasana warsa</h1>
            </Col>
          </Row>
        <div className="d-flex justify-content-center">
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={bgImage5} />
          </Card>
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={wasanawarsa} />
          </Card>
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={wasanawarsa1} />
          </Card>
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <Row>
            <Col>
            <h1 className='kata3'>Guru</h1>
            </Col>
          </Row>
        <div className="d-flex justify-content-center">
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={bgguru} />
          </Card>
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={bgguru3} />
          </Card>
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={bgguru2} />
          </Card>
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <Row>
            <Col>
            <h1 className='kata3'>Guru & Murid</h1>
            </Col>
          </Row>
        <div className="d-flex justify-content-center">
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={bgImage5} />
          </Card>
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={bgImage4} />
          </Card>
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={background3} />
          </Card>
        </div>
      </Carousel.Item>
    </Carousel>
    <Carousel>
      <Carousel.Item>
      <Row>
            <Col>
            <h1 className='kata3'>Seni Tari</h1>
            </Col>
          </Row>
        <div className="d-flex justify-content-center">
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={bgtari} />
          </Card>
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={bgtari1} />
          </Card>
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={bgtari2} />
          </Card>
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <Row>
            <Col>
            <h1 className='kata3'>Rebana</h1>
            </Col>
          </Row>
        <div className="d-flex justify-content-center">
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={Rebana} />
          </Card>
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={Rebana1} />
          </Card>
          <Card className="mx-2" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={Rebana2} />
          </Card>
        </div>
      </Carousel.Item>
    </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
      
      <div className='footer-background'>
      <footer className="footer">
        <Container>
          <Row>
            <Col md={4} className="footer-section">
              <h5>Contact Us</h5>
              <p>
                Alamat: JL. Medayu, RT. 14, RW. 4, Suruh, Kalisal Satu, Reksosari, Kec. Suruh, Kabupaten Semarang, Jawa Tengah 50776<br />
                Phone: 08170581014<br />
                Email: medayusd@gmail.com
              </p>
            </Col>
            <Col md={4} className="footer-section">
              <h5>Link</h5>
              <p onClick={() => navigate('/latar')} style={{ cursor: 'pointer' }}>Latar Sekolah</p>
              <p onClick={() => navigate('/profil')} style={{ cursor: 'pointer' }}>Profil</p>
              <p onClick={() => navigate('/legalitas')} style={{ cursor: 'pointer' }}>Legalitas</p>
            </Col>
            <Col md={4} className="footer-section">
              <h5>Follow Us</h5>
              <div className="d-flex flex-column align-items-start">
                <Button variant="danger" href="https://www.instagram.com/sdnegerimedayu01/" className="mb-2">
                  <i className="bi bi-instagram" href="https://www.instagram.com/sdnegerimedayu01/"></i> Instagram
                </Button>
                <Button variant="success" href="https://chat.whatsapp.com/GqGvSQwzntDE1Vtq8rbDXX" className="mb-2">
                  <i className="bi bi-whatsapp"></i> WhatsApp
                </Button>
                <Button variant="primary" href='https://www.facebook.com/share/NMNH8okoWKiTMphF/?mibextid=LQQJ4d' className="mb-2">
                  <i className="bi bi-facebook"></i> Facebook
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-3">
              <p className="footer-text">&copy; {new Date().getFullYear()} SD Negeri Medayu 01 x Agus Dwi Saputro. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
    </div>
  );
};

export default Satu;
